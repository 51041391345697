// import React from "react";
import Logo from "../../../images/partners/fintech.png";
import Modalp from "../../../components/modal";
import React, { useState } from "react";
import CardFintech from "../partnerCards/fintechaustralia";

const ModalAws = () => {
  const [task1Show, setTask1Show] = useState(false);
  return (
    <>
      <div className="card">
        <button className="click-modal" onClick={() => setTask1Show(true)}></button>
        <div>
          <div className="img-container">
            <img className="img-fluid" src={Logo} alt="PartnerImage" />
          </div>
          <div className="card-body">
            <div className="title">FinTech Australia</div>
            <div className="sub-title">Community <span className="vdivide">|</span> Industry</div>
            <div className="btn-wrap">
              <div className="lbl-ptnrn">Ecosystem Partner</div>
              <Modalp
                show={task1Show}
                handleClose={() => setTask1Show(false)}
                handleShow={() => setTask1Show(true)}
                // modalTitle="Providers"
              >
                <div>
                  <CardFintech/>
                </div>
              </Modalp>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAws;
