import React from "react";
import { Row, Col } from "react-bootstrap";
import FinTech from "../../../images/partners/fintech.png";

const bizagi = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div class>
            <img className="img-fluid" src={FinTech} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>Fintech Australia</h3>
            <div className="lbl-ptnrn">Ecosystem Partner</div>
            <div>
              <a href="https://www.fintechaustralia.org.au/corporate-partners">Visit Website</a>{" "}
              <span>
                
              </span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About Fintech Australia</h4>
            <p>
            The voice of Australian fintech community for advocacy, engagement and growth. Fintech Australia is an influential voice of the Australian fintech community, connecting and fostering growth among members from various sectors like payments, lending, insurtech, regtech, and blockchain.
            </p>
          </div>
          <div className="mt-3">
            <h4>Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>Bring ecosystem partner value to member community</div>
            <div className="p"><span className="vdivide">-{" "}</span>Influence fintech policy discussions</div>
            <div className="p"><span className="vdivide">-{" "}</span>Industry networking events, workshops, member meetups</div>
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>Australia </p>
            {/* <p>Australia <span className="vdivide">|</span>  <span className="vdivide">|</span> Country 3 <span className="vdivide">|</span> Country 4</p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default bizagi;
